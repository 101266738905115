import './index.css'
import RootRender from './RootRender'
import { Input, Button, Select, DatePicker, TimePicker, Radio, Table, Tabs } from 'antd'
import nProgress from 'nprogress'

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.clear = () => {}
}

Button.defaultProps['size'] = 'small'
Input.defaultProps['size'] = 'small'
Select.defaultProps['size'] = 'small'
TimePicker.defaultProps['size'] = 'small'
if (DatePicker.RangePicker.defaultProps) {
  DatePicker.RangePicker.defaultProps['size'] = 'small'
}
Radio.Group.defaultProps['size'] = 'small'
Table['defaultProps'] = {
  size: 'middle',
}
Tabs.defaultProps['animated'] = false

nProgress.configure({ easing: 'ease', speed: 500, parent: '#nprogressHolder' })

RootRender()
