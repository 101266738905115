/** 统一分页信息 */
export interface PageInfo {
  /** 当前页码 */
  pageNo: number
  /** 分页大小 */
  pageSize: number
  /** 总页数 */
  totalPage: number
  /** 总数 */
  totalSize: number
}

function checkIsFunction(anything: any): boolean {
  return typeof anything === 'function'
}

type SuccessCallback = (/** 数据，可能为空 */ data: any, /** 分页信息，可能为空 */ pageInfo?: PageInfo) => void

type FinalCallback = (successCalled: boolean) => void

/**
 * 网络请求回调持有对象
 */
export class BaseRequest {
  _success: SuccessCallback | null
  _failure: ((reason: string, data: any) => void) | null
  _error: ((error: Error) => void) | null
  _final: FinalCallback | null

  successCalled = false

  constructor() {
    this._success = null
    this._failure = null
    this._error = null
    this._final = null
  }
  /**
   * 成功回调
   */
  success(callback: SuccessCallback): BaseRequest {
    if (checkIsFunction(callback)) {
      this._success = callback
    } else {
      console.warn('HLRequest.success 必须传入 function 类型的参数')
    }
    return this
  }

  /**
   * 失败回调，一般不用主动调用。当服务器规定错误码展示逻辑时，才需要设定该回调
   */
  failure(callback: (reason: string, data: any) => void): BaseRequest {
    if (checkIsFunction(callback)) {
      this._failure = callback
    } else {
      console.warn('HLRequest.failure 必须传入 function 类型的参数')
    }
    return this
  }

  /**
   * 网络错误回调，如服务器返回 400 / 500 / 503
   *
   * 绝大部分情况不用主动调用
   */
  error(callback: (error: Error) => void): BaseRequest {
    if (checkIsFunction(callback)) {
      this._error = callback
    } else {
      console.warn('HLRequest.error 必须传入 function 类型的参数')
    }
    return this
  }

  /**
   * 最终回调，一般配合 refresh control end refresh 使用，如
   * ```javascript
   * this.state.refreshing = false
   * ```
   *
   * ⚠️ 不要在这里面使用 HUD 相关 API
   * @param callback 回调函数
   */
  final(callback: FinalCallback) {
    if (checkIsFunction(callback)) {
      this._final = callback
    } else {
      console.warn('HLRequest.final 必须传入 function 类型的参数')
    }
  }
}
