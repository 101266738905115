import { message } from 'antd'
import axios from 'axios'
import { stringify } from 'query-string'
import RootRender from '../RootRender'
import { BaseRequest, PageInfo } from './BaseRequest'
import nProgress from 'nprogress'

interface HeliaoCRMResponse {
  /** 接口请求状态 */
  code?: number
  /** 接口请求失败时返回的信息 */
  msg?: string
  /** 返回数据 */
  data?: {
    pageInfo?: PageInfo
  } & any
}

export class Networking {
  static _basic(url: string, _params?: any, avoidLoginCheck = false, file?: File): BaseRequest {
    nProgress.start()
    const request = new BaseRequest()
    const isFileUpload = file ? true : false
    const contentType = isFileUpload ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
    console.log('🕒发起网络请求', { url, param: _params })
    nProgress.set(0.2)

    let data: FormData | null = null
    if (isFileUpload) {
      data = new FormData()
      data.append('file', file || '无法找到 File 对象')
      if (_params) {
        for (const key in _params) {
          if (_params.hasOwnProperty(key)) {
            const element = _params[key]
            data.append(key, element)
          }
        }
      }
    }
    // 创建axios实例
    const instance = axios.create({
      timeout: 30000, 
    })
    instance
      .post<HeliaoCRMResponse>(url, isFileUpload ? data : stringify(_params), {
        headers: { 'Content-Type': contentType },
      })
      .then(
        //  网络请求成功，服务器返回了数据，即便 code !== 0
        (res) => {
          nProgress.set(0.6)
          const { code, msg } = res.data
          if (code === 0) {
            const { data } = res.data
            let pageInfo: PageInfo | undefined
            if (data) {
              pageInfo = data.pageInfo
            }
            request._success && request._success(data, pageInfo)
            request.successCalled = true
          } else {
            if (!avoidLoginCheck && code === 100) {
              RootRender()
              message.warning('登录状态失效，请重新登录')
            } else if (code === 102) {
              message.warning('登录过于频繁，6小时内禁止登录')
            } else if (request._failure) {
              request._failure(msg || '服务器出错：未给出错误信息', res.data)
            } else {
              message.warning(msg)
            }
          }
        },
        //  网络请求失败，无网络 / 500 / 404 / 超时之类
        (reason) => {
          nProgress.set(0.4)
          console.error(reason)
          if (request._error) {
            request._error(reason)
          } else {
            message.error('网络错误')
          }
        },
      )
      .finally(() => {
        nProgress.done()
        request._final && request._final(request.successCalled)
      })
    return request
  }
}
