import { Networking } from './Networking'

const HaloWang = {
  isLogin() {
    return Networking._basic('/isLogin', null, true)
  },
  login(params: { userName: string; password: string; smsCode?: string }) {
    return Networking._basic('/login', params)
  },
  loginStartCaptcha() {
    return Networking._basic('/login/startCaptcha', null)
  },
  sendSms(params: { userName: string; password: string; challenge?: string; validate?: string; seccode?: string }) {
    return Networking._basic('/sendSms', params)
  },
  logout() {
    return Networking._basic('/logout')
  },
  avatarList(
    params: {
      status?: number
      isToday?: number
      memberId?: number
      sid?: string
      sort?: number
      pageNo?: number
      pageSize?: number
    } = {
      status: 0,
      isToday: 1,
    },
  ) {
    return Networking._basic('/member/userHeadimg/list', params)
  },
  avatarApprove(params: { ids: string[]; status: number }) {
    let finalParams = {
      ...params,
      ids: params.ids.join(','),
    }
    return Networking._basic('/member/userHeadimg/approve', finalParams)
  },
  avatarDisapprove(params: { ids: string[]; status: number }) {
    let finalParams = {
      ...params,
      ids: params.ids.join(','),
    }
    return Networking._basic('/member/userHeadimg/disapprove', finalParams)
  },
  memberCircleReplyList(
    params: {
      memberId?: number
      content?: string
      startDate?: string
      endDate?: string
      status?: number
      pageNo?: number
      pageSize?: number
    } = {
      status: 1,
    },
  ) {
    return Networking._basic('/memberCircleReply/list', params)
  },
  memberCircleReplyApprove(params: { ids: string[] }) {
    let finalParams = {
      ids: params.ids.join(','),
    }
    return Networking._basic('/memberCircleReply/approve', finalParams)
  },
  memberCircleReplyDisapprove(params: { ids: string[]; reason: string }) {
    let finalParams = {
      ...params,
      ids: params.ids.join(','),
    }
    return Networking._basic('/memberCircleReply/disapprove', finalParams)
  },
  crmUsersList() {
    return Networking._basic('/crm/users/list')
  },
  crmUserAdd(params: { brief?: string; id: number; mobile: string; rank: 'user' | 'admin' | 'root'; userName: string }) {
    return Networking._basic('/crm/users/add', params)
  },
  crmUserModify(params: { brief: string; id: number; mobile: string; rank: 'user' | 'admin' | 'root'; userName: string }) {
    return Networking._basic('/crm/users/modify', params)
  },
  crmUserDeactive(params: { id: number }) {
    return Networking._basic('/crm/users/delete', params)
  },
  crmUserActive(params: { id: number }) {
    return Networking._basic('/crm/users/active', params)
  },
  crmUserAddHistory(
    params: {
      id?: number
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/crm/users/history', params)
  },
  spamKeywordList(params: { keyword?: string; pageNo?: number; pageSize?: number } = {}) {
    return Networking._basic('/spamKeyword/list', params)
  },
  spamKeywordAdd(params: { keyword: string }) {
    return Networking._basic('/spamKeyword/add', params)
  },
  spamKeywordDelete(params: { id: number }) {
    return Networking._basic('/spamKeyword/delete', params)
  },
  memberSpamRecordList(params: { memberId?: string; spamMemberId?: string; showFirstSpamInTwoMonths?: boolean; pageNo?: number; pageSize?: number }) {
    return Networking._basic('/memberSpam/recordList', params)
  },
  memberSpamProcessRecord(params: { memberId: number; type: number }) {
    return Networking._basic('/memberSpam/processRecord', params)
  },
  memberSpamAccountSpamList(params: { pageNo?: number; pageSize?: number }) {
    return Networking._basic('/memberSpam/accountSpamList', params)
  },
  selfPopulariseBannerBannerList() {
    return Networking._basic('/selfPopulariseBanner/bannerList')
  },
  selfPopulariseBannerUploadPic(file: File) {
    return Networking._basic('/selfPopulariseBanner/uploadPic', null, false, file)
  },
  selfPopulariseBannerAddBanner(params: { title: string; picUrl: string }) {
    return Networking._basic('/selfPopulariseBanner/addBanner', params)
  },
  selfPopulariseBannerModifyBanner(params: { id: number; title?: string; picUrl?: string }) {
    return Networking._basic('/selfPopulariseBanner/modifyBanner', params)
  },
  selfPopulariseBannerSetBannerOnline(params: { id: number }) {
    return Networking._basic('/selfPopulariseBanner/setBannerOnline', params)
  },
  selfPopulariseBannerDeleteBanner(params: { id: number }) {
    return Networking._basic('/selfPopulariseBanner/deleteBanner', params)
  },
  heliaoFeedBackList(params: { pageNo?: number; pageSize?: number }) {
    return Networking._basic('/heliaoFeedBack/list', params)
  },
  heliaoFeedBackReply(params: { feedbackId: number; content: string }) {
    return Networking._basic('/heliaoFeedBack/reply', params)
  },
  memberReportIncreaseInfo(params: { days: number }) {
    return Networking._basic('/memberReport/increaseInfo', params)
  },
  reportAddFriendRecord(params: { recentDays: number }) {
    return Networking._basic('/report/addFriendRecord', params)
  },
  addFriendRecordList(params: any) {
    return Networking._basic('/addFriendRecord/list', params)
  },
  reportMessageBoardDailyIncrease(params: { recentDays: number }) {
    return Networking._basic('/report/messageBoardDailyIncrease', params)
  },
  reportCrmAssistReport(params: { recentDays: number }) {
    return Networking._basic('/report/crmAssistReport', params)
  },
  reportRenheMoneyTrend(params: { recentDays: number; bizType: number; channelType: number }) {
    return Networking._basic('/report/renheMoneyTrend', params)
  },
  reportRenheMoneyCountTrend(params: { recentDays: number; bizType: number; channelType: number }) {
    return Networking._basic('/report/renheMoneyCountTrend', params)
  },
}

const Junwin = {
  getProfileCoverList(params: { id?: number; memberId?: number; status?: number; pageNo?: number; pageSize?: number }) {
    return Networking._basic('/profileCover/list', params)
  },
  profileCoverApprove(params: { ids: string[] }) {
    let finalParams = {
      ids: params.ids.join(','),
    }
    return Networking._basic('/profileCover/approve', finalParams)
  },
  profileCoverDisapprove(params: { ids: string[] }) {
    let finalParams = {
      ids: params.ids.join(','),
    }
    return Networking._basic('/profileCover/disapprove', finalParams)
  },
  getAssistList(params: { id?: number; title?: string; status?: number; memberId?: number; orderType?: number; pageNo: number; pageSize: number }) {
    return Networking._basic('/assist/list', params)
  },
  approveAssist(params: { ids: string }) {
    return Networking._basic('/assist/approve', params)
  },
  getAssistTemplateList() {
    return Networking._basic('/assist/templateList')
  },
  disApproveAssist(params: { ids: string; templateId?: string; reason?: string }) {
    return Networking._basic('/assist/disapprove', params)
  },
  refreshAssist(params: { ids: number }) {
    return Networking._basic('/assist/refresh', params)
  },
  modifyAssist(params: { id: number; title?: string; tags?: string; content?: string }) {
    return Networking._basic('/assist/modify', params)
  },
  getModifiedAssistList(params: { id?: number; title?: string; memberId?: number; orderType?: number; pageNo: number; pageSize: number }) {
    return Networking._basic('/modifiedAssist/list', params)
  },
  approveModifiedAssist(params: { ids: string }) {
    return Networking._basic('/modifiedAssist/approve', params)
  },
  disapproveModifiedAssist(params: { ids: string; templateId?: string; reason?: string }) {
    return Networking._basic('/modifiedAssist/disapprove', params)
  },
  modifyModifiedAssist(params: { id: number; title?: string; tags?: string; content?: string }) {
    return Networking._basic('/modifiedAssist/modify', params)
  },
  getAssistReplyList(params: { keyword?: string; status?: number; orderType?: number; replyMemberId?: number; pageNo: number; pageSize: number }) {
    return Networking._basic('/assistReply/list', params)
  },
  approveAssistReply(params: { ids: string }) {
    return Networking._basic('/assistReply/approve', params)
  },
  disapproveAssistReply(params: { ids: string; reason?: string }) {
    return Networking._basic('/assistReply/disapprove', params)
  },
  getAssistTagList(params: { tagId?: string; assistId?: string; orderType?: number; pageNo: number; pageSize: number }) {
    return Networking._basic('/assistTag/list', params)
  },
  deleteAssistTag(params: { id: number }) {
    return Networking._basic('/assistTag/delete', params)
  },
  getMemberList(params: {
    memberId?: string
    memberName?: string
    memberSid?: string
    company?: string
    mobile?: string
    email?: string
    invitationNo?: number
    orderType?: number
    orderFiled?: number
    notInHalfHour?: boolean
    preset?: number
    pageNo: number
    pageSize: number
  }) {
    return Networking._basic('/member/list', params)
  },
  updateMemberStatus(params: { memberIds: string; type: number }) {
    return Networking._basic('/member/updateStatus', params)
  },
  getWorkExperienceList(params: { status: number; inHalfHour: boolean; pageNo: number; pageSize: number }) {
    return Networking._basic('/member/workExperience/list', params)
  },
  updateWorkExperienceStatus(params: { memberIds: string; status: number }) {
    return Networking._basic('/member/workExperience/updateStatus', params)
  },
  getMemberInfoStateList(params: { status: number; inHalfHour: boolean; pageNo: number; pageSize: number }) {
    return Networking._basic('/member/memberInfoState/list', params)
  },
  getMemberInfoStateFilteredList(params: { status: number; inHalfHour: boolean; pageNo: number; pageSize: number }) {
    return Networking._basic('/member/memberInfoState/listFiltered', params)
  },
  getMobileMemberList(params: { pageNo: number; pageSize: number }) {
    return Networking._basic('/member/mobileMember/list', params)
  },
  getSilentList(params: { pageNo: number; pageSize: number }) {
    return Networking._basic('/member/silentList', params)
  },
  updateMemberInfoState(params: { memberIds: string; status: number }) {
    return Networking._basic('/member/memberInfoState/updateStatus', params)
  },
  updateMemberSilentStatus(params: { memberId: string; status: number; mark: string }) {
    return Networking._basic('/member/updateMemberSilentStatus', params)
  },
  getCrmEmailTemplateList(params: {
    pageNo: number
    pageSize: number
    id?: number
    name?: string
    type?: string
    subject?: string
    aim?: string
    target?: string
    purpose?: string
    orderType?: number
  }) {
    return Networking._basic('/crmEmailTemplate/list', params)
  },
  getCrmEmailTemplateTypeList() {
    return Networking._basic('/crmEmailTemplate/typeList', {})
  },
  getCrmEmailTemplateDetail(params: { id: number }) {
    return Networking._basic('/crmEmailTemplate/detail', params)
  },
  modifyCrmEmailTemplate(params: {
    id: number
    name: string
    type: string
    returnAddress?: string
    fromEmailAddressType?: string
    fromName?: string
    sendEmail?: string
    resendEmail?: string
    subject: string
    content: string
    aim?: string
    target?: string
    purpose?: string
    traceUrl?: string
    traceUrl2?: string
    memo?: string
  }) {
    return Networking._basic('/crmEmailTemplate/modify', params)
  },
  deleteCrmEmailTemplate(params: { id: number }) {
    return Networking._basic('/crmEmailTemplate/delete', params)
  },
  sendCrmEmailTemplate(params: { subject: string; content: string; fromName: string; fromAddress: string; toName: string; toAddress: string }) {
    return Networking._basic('/crmEmailTemplate/send', params)
  },
  addCrmEmailTemplate(params: {
    name: string
    type: string
    returnAddress?: string
    fromEmailAddressType?: string
    fromName?: string
    sendEmail?: string
    resendEmail?: string
    subject: string
    content: string
    aim?: string
    target?: string
    purpose?: string
    traceUrl?: string
    traceUrl2?: string
    memo?: string
  }) {
    return Networking._basic('/crmEmailTemplate/add', params)
  },
  getMessageTemplateList(params: { pageNo: number; pageSize: number; id?: number; name?: string; type?: string; subject?: string; crmEmailTemplateId?: number; orderType?: number }) {
    return Networking._basic('/messageTemplate/list', params)
  },
  getMessageTemplateDetail(params: { id: number }) {
    return Networking._basic('/messageTemplate/detail', params)
  },
  modifyMessageTemplate(params: { pageNo: number; pageSize: number; id: number; name: string; type: string; subject: string; crmEmailTemplateId?: number; content?: string; memo?: string }) {
    return Networking._basic('/messageTemplate/modify', params)
  },
  deleteMessageTemplateDetail(params: { id: number }) {
    return Networking._basic('/messageTemplate/delete', params)
  },
  addMessageTemplate(params: { type: string; name: string; subject: string; crmEmailTemplateId?: number; content: string; memo?: string; fromName?: string }) {
    return Networking._basic('/messageTemplate/add', params)
  },
  getMemberDetail(params: { memberId: number }) {
    return Networking._basic('/member/detail', params)
  },
  updateMember(params: { memberId: number; professional?: string; specialties?: string; interests?: string; association?: string; awards?: string; preferredTags?: string; aimTags?: string }) {
    return Networking._basic('/member/updateMore', params)
  },
}

const Ray = {
  // memberId	否	int	用户id
  // content	否	string	内容
  // startDate	否	string	开始日期:yyyy-MM-dd
  // endDate	否	string	结束日期:yyyy-MM-dd
  // status	否	int	审核状态 0:全部; 1:未审核; 2:审核通过; -1:审核不通过;
  // pageNo	否	int int	当前页码
  // pageSize	否	string	分页大小
  memberCircleList(
    params: {
      memberId?: number
      content?: string
      startDate?: string
      endDate?: string
      status?: number
      pageNo?: number
      pageSize?: number
    } = {
      status: 1,
    },
  ) {
    return Networking._basic('/memberCircle/list', params)
  },
  memberCircleApprove(params: { ids: string[] }) {
    let finalParams = {
      ids: params.ids.join(','),
    }
    return Networking._basic('/memberCircle/approve', finalParams)
  },
  memberCircleDisapprove(params: { ids: string[]; reason: string }) {
    let finalParams = {
      ...params,
      ids: params.ids.join(','),
    }
    return Networking._basic('/memberCircle/disapprove', finalParams)
  },
  memberCircleSediment(params: { ids: string[] }) {
    let finalParams = {
      ...params,
      ids: params.ids.join(','),
    }
    return Networking._basic('/memberCircle/sediment', finalParams)
  },
  /** 监管者列表 */
  regulatorList(params: { id: string; name: string }) {
    return Networking._basic('/regulator/list', params)
  },
  /** 增加监管者 */
  regulatorAdd(params: { id: number }) {
    return Networking._basic('/regulator/add', params)
  },
  /** 删除监管者 */
  regulatorDelete(params: { id: number }) {
    return Networking._basic('/regulator/delete', params)
  },
  /** 会员列表 */
  vipMemberList(params: { id: string; type: number }) {
    return Networking._basic('/vipMember/list', params)
  },
  /** 账号变更记录 */
  vipMemberAccountTypeChangeLog(
    params: {
      memberId?: number
      startDate?: string
      endDate?: string
      status?: number
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/vipMember/accountTypeChangeLog', params)
  },
  /** 特权使用记录 */
  vipMemberAccountPrivilegeUseLog(
    params: {
      memberId?: number
      startDate?: string
      endDate?: string
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/vipMember/accountPrivilegeUseLog', params)
  },
  /** 付费会员订单列表 */
  vipMemberAccountTypePayLog(
    params: {
      memberId?: number
      status?: number
      startDate?: string
      endDate?: string
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/vipMember/accountTypePayLog', params)
  },
  /** 付费会员订单备注修改 */
  vipMemberAccountTypePayLogModifyRemark(params: { id: number; remark: string }) {
    return Networking._basic('/vipMember/accountTypePayLog/modifyRemark', params)
  },
  /** 我推列表 */
  selfPopulariseList(
    params: {
      memberId?: number
      content?: string
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/selfPopularise/list', params)
  },
  /** 审核不通过 */
  selfPopulariseDisapprove(params: { ids: string[] }) {
    let finalParams = {
      ids: params.ids.join(','),
    }
    return Networking._basic('/selfPopularise/disapprove', finalParams)
  },
  /** 实名认证管理 */
  realNameList(
    params: {
      status?: number
      type?: number
      memberId?: number
      yearMonth?: string
      lastFourNumber?: string
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/realName/list', params)
  },
  /** 实名认证管理 审核通过 */
  realNameApprove(params: { ids: string }) {
    return Networking._basic('/realName/approve', params)
  },
  /** 实名认证管理 审核不通过 */
  realNameDisapprove(params: { ids: number[] }) {
    let finalParams = {
      ids: params.ids.join(','),
    }
    return Networking._basic('/realName/disapprove', finalParams)
  },
  /** 实名认证管理 清除仍扣费的状态 */
  realNameClearUnpass(params: { ids: number[] }) {
    let finalParams = {
      ids: params.ids.join(','),
    }
    return Networking._basic('/realName/clearUnpass', finalParams)
  },
  /** 实名认证管理 发送邮件 */
  realNameSendEmail(params: { id: number; memberId: number }) {
    return Networking._basic('/realName/sendEmail', params)
  },
  /** 实名认证图片管理 列表 */
  realNameAuthPicList(
    params: {
      status?: number
      memberId?: number
      identitification?: string
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/realNameAuthPic/list', params)
  },
  /** 实名认证图片管理 审核通过 */
  realNameAuthPicApprove(params: { id: number }) {
    return Networking._basic('/realNameAuthPic/approve', params)
  },
  /** 实名认证图片管理 审核不通过 */
  realNameAuthPicDisapprove(params: { id: number; noPassReason: string }) {
    return Networking._basic('/realNameAuthPic/disapprove', params)
  },
  /** 实名认证请求 列表 */
  realNameAuthRequestList(
    params: {
      memberId?: number
      requireId?: number
      startDate?: string
      endDate?: string
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/realNameAuthRequest/list', params)
  },
  /** 手动充值人和币 */
  memberAccountAddMoney(params: { memberId: number; amount: number; content: string }) {
    return Networking._basic('/memberAccount/addMoney', params)
  },
  /** 充值记录 */
  memberAccountChargeRecords(
    params: {
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/memberAccount/chargeRecords', params)
  },
  /** 消费记录列表 */
  memberAccountConsumptionRecords(
    params: {
      memberId?: number
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/memberAccount/consumptionRecords', params)
  },
  /** 职位认证(职位认证历史也用这个接口，不传status只传memberId即可) */
  jobAuthList(
    params: {
      status?: number
      memberId?: number
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/jobAuth/list', params)
  },
  /** 职位认证 审核通过 */
  jobAuthApprove(params: { id: number }) {
    return Networking._basic('/jobAuth/approve', params)
  },
  /** 职位认证 审核不通过 */
  jobAuthDisapprove(params: { id: number; noPassReason: string }) {
    return Networking._basic('/jobAuth/disapprove', params)
  },
  /** 人和网feedback的list */
  feedbackList(
    params: {
      status?: string
      type?: string
      orderType?: number
      crmUserId?: number
      email?: string
      startTime?: string
      endTime?: string
      replyStartTime?: string
      replyEndTime?: string
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/feedback/list', params)
  },
  /** 人和网feedback detail */
  feedbackDetail(params: { id: number }) {
    return Networking._basic('/feedback/detail', params)
  },
  /** 人和网feedback 暂时挂起 */
  feedbackHold(params: { ids: string[] }) {
    return Networking._basic('/feedback/hold', params)
  },
  /** 人和网feedback 删除（包含彻底删除） */
  feedbackdDelete(params: { ids: string[]; physical: boolean }) {
    return Networking._basic('/feedback/delete', params)
  },
  /** 人和网feedback 回复模板列表 */
  feedbackTemplateList() {
    return Networking._basic('/feedback/templateList', {})
  },
  /** 人和网feedback 回复feedback */
  feedbackReply(params: { feedbackId: number; templateId: number; content: string }) {
    return Networking._basic('/feedback/reply', params)
  },
  /** 职位认证(职位认证历史也用这个接口，不传status只传memberId即可) */
  heliaoActivityList(
    params: {
      startTime?: string
      endTime?: string
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/heliaoActivity/list', params)
  },
  /** 发送和小秘  */
  heliaoActivitySend(params: { memberIds: string; title: string; content: string }) {
    return Networking._basic('/heliaoActivity/send', params)
  },
  /** 余额提现 */
  withdrawalList(
    params: {
      status?: number
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/withdrawal/list', params)
  },
  /** 修改余额提现状态 */
  withdrawalUpdateStatus(params: { withdrawalId: number; status: number }) {
    return Networking._basic('/withdrawal/updateStatus', params)
  },
  /** 人脉秘书列表 */
  vipMemberRmmsList(
    params: {
      applicantMemberId?: number
      beApplicantMemberId?: number
      status?: number
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/vipMember/rmmsList', params)
  },
  /** 人脉秘书登记 */
  vipMemberRmmsRegistration(params: { applicantMemberId: number; beApplicantMemberId: number; description: string }) {
    return Networking._basic('/vipMember/rmmsRegistration', params)
  },
  /** 更新人脉秘书状态  */
  vipMemberUpdateStatus(params: { rmmsId: number; status: number; comment: string }) {
    return Networking._basic('/vipMember/updateStatus', params)
  },
  /** 不通过模板 */
  offerTemplateList() {
    return Networking._basic('/offer/templateList', {})
  },
  /**----------------- 供需管理 -------------------*/
  /** 供需列表 */
  offerList(
    params: {
      offerId?: number
      title?: string
      status?: number
      memberId?: number
      type?: number
      orderType?: number
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/offer/list', params)
  },
  /** 审核通过 */
  offerApprove(params: { offerIds: string }) {
    return Networking._basic('/offer/approve', params)
  },
  /** 审核不通过 */
  offerDisapprove(params: { offerIds: string; templateId: number; reason?: string }) {
    return Networking._basic('/offer/disapprove', params)
  },
  /** 置顶 */
  offerPopUp(params: { offerId: number }) {
    return Networking._basic('/offer/popUp', params)
  },
  /** 修改供需 */
  offerModify(params: { offerId: number; type: number; deletePic?: boolean; title: string; content: string; tags?: string }) {
    return Networking._basic('/offer/modify', params)
  },
  /**----------------- 编辑后的供需 -------------------*/
  /** 供需列表 */
  offerModifyList(
    params: {
      offerId?: number
      title?: string
      memberId?: number
      type?: number
      orderType?: number
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/offerModify/list', params)
  },
  /** 审核通过 */
  offerModifyApprove(params: { offerIds: string }) {
    return Networking._basic('/offerModify/approve', params)
  },
  /** 审核不通过 */
  offerModifyDisapprove(params: { offerIds: string; templateId: number; reason?: string }) {
    return Networking._basic('/offerModify/disapprove', params)
  },
  /** 修改供需 */
  offerModifyModify(params: { offerId: number; type: number; deletePic?: boolean; title: string; content: string; tags?: string }) {
    return Networking._basic('/offerModify/modify', params)
  },
  /**----------------- 供需回复 -------------------*/
  /** 供需回复列表 */
  offerReplyList(
    params: {
      keyword?: string
      status?: number
      orderType?: number
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/offerReply/list', params)
  },
  /** 供需回复审核通过 */
  offerReplyApprove(params: { offerReplyIds: string }) {
    return Networking._basic('/offerReply/approve', params)
  },
  /** 供需回复审核不通过 */
  offerReplyDisapprove(params: { offerReplyIds: string; reason: string }) {
    return Networking._basic('/offerReply/disapprove', params)
  },
  /** 供需标签列表 */
  offerTagList(
    params: {
      tagId?: number
      offerId?: number
      orderType?: number
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/offerTag/list', params)
  },
  /** 删除供需标签 */
  offerTagDelete(params: { tagId: number }) {
    return Networking._basic('/offerTag/delete', params)
  },
  /** 编辑供需标签 */
  offerTagModify(params: { tagId: number; title: string }) {
    return Networking._basic('/offerTag/modify', params)
  },
  /** Banner列表 */
  bannerList(
    params: {
      module?: number
      pageNo?: number
      pageSize?: number
    } = {},
  ) {
    return Networking._basic('/banner/list', params)
  },
  /** bannerUpload */
  bannerUpload(file: File) {
    return Networking._basic('/banner/upload', null, false, file)
  },
  /** BannerAdd */
  bannerAdd(params: { title: string; picName: string; linkUrl: string; type: number; ordering: number; module: number }) {
    return Networking._basic('/banner/add', params)
  },
  /** 修改优先级 */
  bannerUpdatePriority(params: { bannerId: number; priority: number }) {
    return Networking._basic('/banner/updatePriority', params)
  },
  /** 修改状态 */
  bannerUpdateStatus(params: { bannerId: number; status: number }) {
    return Networking._basic('/banner/updateStatus', params)
  },
}

export const API = { ...HaloWang, ...Junwin, ...Ray }
