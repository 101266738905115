import { message, ConfigProvider } from 'antd'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { API } from './Networkings/API'
import zhCN from 'antd/es/locale/zh_CN'

const App = React.lazy(() => import('./App'))
const Login = React.lazy(() => import('./Login'))

export default function() {
  API.isLogin()
    .success(res => {
      ReactDOM.render(
        <Suspense fallback={null}>
          <ConfigProvider locale={zhCN}>
            <App router={JSON.parse(res)} />
          </ConfigProvider>
        </Suspense>,
        document.getElementById('root'),
      )
    })
    .failure((reason, data) => {
      console.log(reason, data)
      ReactDOM.render(
        <Suspense fallback={null}>
          <ConfigProvider locale={zhCN}>
            <Login />
          </ConfigProvider>
        </Suspense>,
        document.getElementById('root'),
      )
    })
    .error(error => {
      message.error('网络错误')
      console.log(error)
    })
}
